import * as React from "react";
import * as Mui from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "./Navbar";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PaidIcon from "@mui/icons-material/Paid";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  getQuote1,
  getQuote2,
  saveHistory,
  getHistory,
  resetState,
  deleteHistory,
  setState,
  takeProfit,
} from "../redux/reducers/dashboardReducer";
import { indexes } from "../redux/reducers/mainReducer";
import toast from "react-hot-toast";
import useWebSocket from "react-use-websocket";
import ProfitChart from "./ProfitChart";

const addModelStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { md: 1000 },
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const today = new Date();

// const SERVER_SOCKET_API_URL_LOCAL = "ws://localhost:5000/ws/dashboard";
const SERVER_SOCKET_API_URL_DEPLOY =
  "wss://deardrphil-stock-server.herokuapp.com/ws/dashboard";

const types = [
  { label: "Long", no: 0 },
  { label: "Short", no: 1 },
];

function Row(props) {
  const { row, status } = props;
  const [open, setOpen] = React.useState(false);
  const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] =
    React.useState(false);
  const [takeConfirmDialogOpen, setTakeConfirmDialogOpen] =
    React.useState(false);
  const dispatch = useDispatch();

  const handleDelete = React.useCallback(
    (id) => {
      dispatch(deleteHistory({ id: id }));

      toast.error("Successfully deleted!");
    },
    [dispatch]
  );

  const handleDeleteDialogOpen = React.useCallback(() => {
    setDeleteConfirmDialogOpen(true);
  }, []);

  const handleDeleteDialogClose = React.useCallback(() => {
    setDeleteConfirmDialogOpen(false);
  }, []);

  const handleTakeDialogOpen = React.useCallback(() => {
    setTakeConfirmDialogOpen(true);
  }, []);

  const handleTakeDialogClose = React.useCallback(() => {
    setTakeConfirmDialogOpen(false);
  }, []);

  const handleTake = React.useCallback(
    (id) => {
      dispatch(
        takeProfit({
          id: id,
          notionalValue1: row.notionalValue1,
          latestPrice1: row.latestPrice1,
          profitOrLoss1: row.profitOrLoss1,
          notionalValue2: row.notionalValue2,
          latestPrice2: row.latestPrice2,
          profitOrLoss2: row.profitOrLoss2,
          netProfit: row.netProfit,
        })
      );

      toast.success("Successfully took a profit/loss!");
      handleTakeDialogClose();
    },
    [dispatch, row, handleTakeDialogClose]
  );

  return (
    <React.Fragment>
      <Mui.TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <Mui.TableCell>
          <Mui.IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </Mui.IconButton>
        </Mui.TableCell>
        <Mui.TableCell>
          {row.status ? (
            <Mui.Chip color="warning" label="Closed"></Mui.Chip>
          ) : (
            <Mui.Chip color="success" label="Opened"></Mui.Chip>
          )}
        </Mui.TableCell>
        <Mui.TableCell component="th" scope="row">
          {new Date(row.date).toLocaleDateString()}
        </Mui.TableCell>
        {status === true && (
          <Mui.TableCell component="th" scope="row" align="left">
            {new Date(row.closingDate).toLocaleDateString()}
          </Mui.TableCell>
        )}
        <Mui.TableCell align="right">
          {indexes[row.index1].symbol} & {indexes[row.index2].symbol}
        </Mui.TableCell>
        <Mui.TableCell align="right">{row.netProfit.toFixed(2)}</Mui.TableCell>
        <Mui.TableCell>
          <Mui.Box
            sx={{
              display: "flex",
            }}
          >
            {!row.status && (
              <Mui.IconButton
                color="success"
                aria-label="take"
                size="small"
                onClick={handleTakeDialogOpen}
              >
                <PaidIcon fontSize="small" />
              </Mui.IconButton>
            )}

            <Mui.IconButton
              color="error"
              aria-label="delete"
              size="small"
              onClick={handleDeleteDialogOpen}
            >
              <DeleteIcon fontSize="small" />
            </Mui.IconButton>
          </Mui.Box>
        </Mui.TableCell>
      </Mui.TableRow>

      <Mui.TableRow>
        <Mui.TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Mui.Collapse in={open} timeout="auto" unmountOnExit>
            <Mui.Box sx={{ margin: 1 }}>
              <Mui.Typography variant="h6" gutterBottom component="div">
                {indexes[row.index1].symbol} & {indexes[row.index2].symbol}
              </Mui.Typography>

              <Mui.Table>
                <Mui.TableHead>
                  <Mui.TableRow>
                    <Mui.TableCell>Index</Mui.TableCell>
                    <Mui.TableCell>Long/Short</Mui.TableCell>
                    <Mui.TableCell align="right">Size ($/point)</Mui.TableCell>
                    <Mui.TableCell align="right">
                      Opening Price ($)
                    </Mui.TableCell>
                    <Mui.TableCell align="right">
                      Notional Value ($)
                    </Mui.TableCell>
                    <Mui.TableCell align="right">
                      Latest Price ($)
                    </Mui.TableCell>
                    <Mui.TableCell align="right">Profit/Loss ($)</Mui.TableCell>
                  </Mui.TableRow>
                </Mui.TableHead>

                <Mui.TableBody>
                  <Mui.TableRow>
                    <Mui.TableCell>{indexes[row.index1].symbol}</Mui.TableCell>
                    <Mui.TableCell>
                      <Mui.Chip
                        label={row.type1 === 0 ? "Long" : "Short"}
                        color={row.type1 === 0 ? "primary" : "error"}
                      />
                    </Mui.TableCell>
                    <Mui.TableCell align="right">{row.size1}</Mui.TableCell>
                    <Mui.TableCell align="right">
                      {row.openingPrice1}{" "}
                      <small>({new Date(row.date).toLocaleDateString()})</small>
                    </Mui.TableCell>
                    <Mui.TableCell align="right">
                      {row.notionalValue1.toFixed(2)}
                    </Mui.TableCell>
                    <Mui.TableCell align="right">
                      {row.latestPrice1.toFixed(2)}
                    </Mui.TableCell>
                    <Mui.TableCell align="right">
                      {row.profitOrLoss1.toFixed(2)}
                    </Mui.TableCell>
                  </Mui.TableRow>

                  <Mui.TableRow>
                    <Mui.TableCell>{indexes[row.index2].symbol}</Mui.TableCell>
                    <Mui.TableCell>
                      <Mui.Chip
                        label={row.type2 === 0 ? "Long" : "Short"}
                        color={row.type2 === 0 ? "primary" : "error"}
                      />
                    </Mui.TableCell>
                    <Mui.TableCell align="right">{row.size2}</Mui.TableCell>
                    <Mui.TableCell align="right">
                      {row.openingPrice2}
                      <small>({new Date(row.date).toLocaleDateString()})</small>
                    </Mui.TableCell>
                    <Mui.TableCell align="right">
                      {row.notionalValue2.toFixed(2)}
                    </Mui.TableCell>
                    <Mui.TableCell align="right">
                      {row.latestPrice2.toFixed(2)}
                    </Mui.TableCell>
                    <Mui.TableCell align="right">
                      {row.profitOrLoss2.toFixed(2)}
                    </Mui.TableCell>
                  </Mui.TableRow>
                </Mui.TableBody>
              </Mui.Table>
            </Mui.Box>
          </Mui.Collapse>
        </Mui.TableCell>
      </Mui.TableRow>

      {/* Delete confirmation dialog */}
      <Mui.Dialog
        open={deleteConfirmDialogOpen}
        onClose={handleDeleteDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Mui.DialogTitle id="alert-dialog-title">
          {"Do you want to delete this trade?"}
        </Mui.DialogTitle>
        <Mui.DialogContent>
          <Mui.DialogContentText id="alert-dialog-description">
            Please make sure that you are gonna delete this trade!
          </Mui.DialogContentText>
        </Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.Button color="error" onClick={handleDeleteDialogClose}>
            Disagree
          </Mui.Button>
          <Mui.Button
            color="success"
            onClick={() => handleDelete(row.id)}
            autoFocus
          >
            Agree
          </Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>

      {/* Take profit confirmation dialog */}
      <Mui.Dialog
        open={takeConfirmDialogOpen}
        onClose={handleTakeDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Mui.DialogTitle id="alert-dialog-title">
          {"Do you want to take the profit/loss from this trade?"}
        </Mui.DialogTitle>
        <Mui.DialogContent>
          <Mui.DialogContentText id="alert-dialog-description">
            Please make sure that you are gonna take the profit/loss from this
            trade!
          </Mui.DialogContentText>
        </Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.Button color="error" onClick={handleTakeDialogClose}>
            Disagree
          </Mui.Button>
          <Mui.Button
            color="success"
            onClick={() => handleTake(row.id)}
            autoFocus
          >
            Agree
          </Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>
    </React.Fragment>
  );
}

export default function Dashboard(props) {
  const dispatch = useDispatch();
  const [addModelOpen, setAddModelOpen] = React.useState(false);
  const [index1, setIndex1] = React.useState(indexes[0].no);
  const [type1, setType1] = React.useState(types[0].no);
  const [size1, setSize1] = React.useState(0);
  const [openingPrice1, setOpeningPrice1] = React.useState(0);
  const [notionalValue1, setNotionalValue1] = React.useState(0);
  const [profitOrLoss1, setProfitOrLoss1] = React.useState(0);
  const [index2, setIndex2] = React.useState(indexes[0].no);
  const [type2, setType2] = React.useState(types[0].no);
  const [size2, setSize2] = React.useState(0);
  const [openingPrice2, setOpeningPrice2] = React.useState(0);
  const [notionalValue2, setNotionalValue2] = React.useState(0);
  const [profitOrLoss2, setProfitOrLoss2] = React.useState(0);
  const [netProfit, setNetProfit] = React.useState(0);
  const rows = useSelector((state) => state.dashboard.rows);
  const latestPrice1 = useSelector((state) => state.dashboard.latestPrice1);
  const latestPrice2 = useSelector((state) => state.dashboard.latestPrice2);
  const [totalProfit, setTotalProfit] = React.useState(0);
  const [openedTotalProfit, setOpenedTotalProfit] = React.useState(0);
  const { lastMessage } = useWebSocket(SERVER_SOCKET_API_URL_DEPLOY, {
    onOpen: () => console.log("WebSocket connection opened"),
    onClose: () => console.log("WebSocket connection closed"),
    onError: (event) => console.error("WebSocket error:", event),
  });
  const [ratio, setRatio] = React.useState(0);

  const handleAddModelOpen = React.useCallback(() => {
    setAddModelOpen(true);
  }, []);

  const handleAddModelClose = React.useCallback(() => {
    setAddModelOpen(false);
  }, []);

  const handleIndex1Change = React.useCallback((e) => {
    setIndex1(e.target.value);
  }, []);

  const handleType1Change = React.useCallback((e) => {
    setType1(e.target.value);
  }, []);

  const handleSize1Change = React.useCallback((e) => {
    setSize1(e.target.value);
  }, []);

  const handleOpeningPrice1Change = React.useCallback((e) => {
    setOpeningPrice1(e.target.value);
  }, []);

  const handleIndex2Change = React.useCallback((e) => {
    setIndex2(e.target.value);
  }, []);

  const handleType2Change = React.useCallback((e) => {
    setType2(e.target.value);
  }, []);

  const handleSize2Change = React.useCallback((e) => {
    setSize2(e.target.value);
  }, []);

  const handleOpeningPrice2Change = React.useCallback((e) => {
    setOpeningPrice2(e.target.value);
  }, []);

  // Get history
  React.useEffect(() => {
    dispatch(getHistory());
  }, [dispatch]);

  // Get quote for index1
  React.useEffect(() => {
    dispatch(getQuote1({ index: indexes[index1].symbol }));
  }, [index1, dispatch]);

  React.useEffect(() => {
    setNotionalValue1(latestPrice1 * size1);
  }, [size1, latestPrice1]);

  React.useEffect(() => {
    if (type1 === 0) {
      // Long
      setProfitOrLoss1(((latestPrice1 - openingPrice1) * size1).toFixed(2));
    } else {
      // Short
      setProfitOrLoss1(((openingPrice1 - latestPrice1) * size1).toFixed(2));
    }
  }, [latestPrice1, openingPrice1, size1, type1]);

  // Get quote for index2
  React.useEffect(() => {
    dispatch(getQuote2({ index: indexes[index2].symbol }));
  }, [index2, dispatch]);

  React.useEffect(() => {
    setNotionalValue2(latestPrice2 * size2);
  }, [size2, latestPrice2]);

  React.useEffect(() => {
    if (type2 === 0) {
      // Long
      setProfitOrLoss2(((latestPrice2 - openingPrice2) * size2).toFixed(2));
    } else {
      // Short
      setProfitOrLoss2(((openingPrice2 - latestPrice2) * size2).toFixed(2));
    }
  }, [latestPrice2, openingPrice2, size2, type2]);

  // Net profit
  React.useEffect(() => {
    setNetProfit(profitOrLoss1 * 1.0 + profitOrLoss2 * 1.0);
  }, [profitOrLoss1, profitOrLoss2]);

  // Save
  const handleSave = React.useCallback(() => {
    dispatch(
      saveHistory({
        date: today.toLocaleDateString(),
        index1: indexes[index1].no,
        type1: type1,
        size1: size1,
        openingPrice1: openingPrice1,
        notionalValue1: notionalValue1,
        latestPrice1: latestPrice1,
        profitOrLoss1: profitOrLoss1,
        index2: indexes[index2].no,
        type2: type2,
        size2: size2,
        openingPrice2: openingPrice2,
        notionalValue2: notionalValue2,
        latestPrice2: latestPrice2,
        profitOrLoss2: profitOrLoss2,
        netProfit: netProfit,
      })
    );

    // close the modal and reset
    setAddModelOpen(false);
    toast.success("Successfully recorded!");
    dispatch(resetState());
  }, [
    index1,
    type1,
    size1,
    openingPrice1,
    notionalValue1,
    latestPrice1,
    profitOrLoss1,
    index2,
    type2,
    size2,
    openingPrice2,
    notionalValue2,
    latestPrice2,
    profitOrLoss2,
    netProfit,
    dispatch,
  ]);

  // Update rows in real time
  React.useEffect(() => {
    if (lastMessage !== null) {
      dispatch(setState(JSON.parse(lastMessage.data)));
    }
  }, [lastMessage, dispatch]);

  React.useEffect(() => {
    if (latestPrice1 * 1.0 > latestPrice2 * 1.0) {
      setRatio(((latestPrice1 * 1.0) / latestPrice2) * 1.0);
    } else setRatio(((latestPrice2 * 1.0) / latestPrice1) * 1.0);
  }, [latestPrice1, latestPrice2]);

  // Calc total profit
  React.useEffect(() => {
    const total = rows
      .filter((row) => row.status)
      .reduce((acc, row) => {
        return acc + row.netProfit;
      }, 0);

    const openedTotal = rows
      .filter((row) => !row.status)
      .reduce((acc, row) => {
        return acc + row.netProfit;
      }, 0);

    setTotalProfit(total);
    setOpenedTotalProfit(openedTotal);
  }, [rows]);

  const ProfitChartMemo = React.useMemo(() => {
    return <ProfitChart rows={rows.filter((row) => row.status)} />;
  }, [rows]);

  return (
    <Mui.Box>
      <Navbar />

      <Mui.Container
        sx={{
          display: "flex",
          marginTop: 2,
          justifyContent: "end",
        }}
      >
        <Mui.Button variant="contained" onClick={handleAddModelOpen}>
          + New
        </Mui.Button>
      </Mui.Container>

      <Mui.Container
        maxWidth="xl"
        sx={{
          height: "80vh",
          display: "flex",
          marginY: 2,
        }}
      >
        {/* Table */}
        <Mui.TableContainer component={Mui.Paper}>
          <Mui.Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginX: 2,
            }}
          >
            {/* Title */}
            <Mui.Typography
              sx={{
                padding: 2,
                textTransform: "uppercase",
                fontWeight: "600",
              }}
            >
              Your current opened trades
            </Mui.Typography>

            <Mui.Chip
              label={`Total Profit: ${openedTotalProfit.toFixed(2)} ($)`}
            ></Mui.Chip>
          </Mui.Box>

          <Mui.Table
            sx={{
              margin: 2,
              width: "97%",
            }}
            aria-label="collapsible table"
          >
            <Mui.TableHead>
              <Mui.TableRow>
                <Mui.TableCell width={1} />
                <Mui.TableCell width={1} />
                <Mui.TableCell>Date</Mui.TableCell>
                <Mui.TableCell align="right">Pair</Mui.TableCell>
                <Mui.TableCell align="right">NET Profit ($)</Mui.TableCell>
                <Mui.TableCell width={1} />
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {rows
                .filter((row) => !row.status)
                .map((row) => (
                  <Row status={false} key={row.id} row={row} />
                ))}
            </Mui.TableBody>
          </Mui.Table>
        </Mui.TableContainer>
      </Mui.Container>

      {ProfitChartMemo}

      <Mui.Container
        maxWidth="xl"
        sx={{
          height: "80vh",
          display: "flex",
          paddingTop: 2,
        }}
      >
        <Mui.TableContainer component={Mui.Paper}>
          {/* Title */}
          <Mui.Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginX: 2,
            }}
          >
            <Mui.Typography
              sx={{
                padding: 2,
                textTransform: "uppercase",
                fontWeight: "600",
              }}
            >
              Your closed trades
            </Mui.Typography>

            <Mui.Chip
              label={`Total Profit: ${totalProfit.toFixed(2)} ($)`}
            ></Mui.Chip>
          </Mui.Box>

          <Mui.Table
            sx={{
              margin: 2,
              width: "97%",
            }}
            aria-label="collapsible table"
          >
            <Mui.TableHead>
              <Mui.TableRow>
                <Mui.TableCell width={1} />
                <Mui.TableCell width={1} />
                <Mui.TableCell>Open Date</Mui.TableCell>
                <Mui.TableCell>Closed Date</Mui.TableCell>
                <Mui.TableCell align="right">Pair</Mui.TableCell>
                <Mui.TableCell align="right">NET Profit ($)</Mui.TableCell>
                <Mui.TableCell width={1} />
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {rows
                .filter((row) => row.status)
                .map((row) => (
                  <Row status={true} key={row.id} row={row} />
                ))}
            </Mui.TableBody>
          </Mui.Table>
        </Mui.TableContainer>
      </Mui.Container>

      <Mui.Modal
        open={addModelOpen}
        onClose={handleAddModelClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Mui.Box sx={addModelStyle}>
          <Mui.Container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Mui.Typography
              variant="h4"
              sx={{
                paddingRight: 3,
              }}
            >
              {indexes[index1].symbol}
            </Mui.Typography>
            &
            <Mui.Typography
              variant="h4"
              sx={{
                paddingLeft: 3,
                paddingRight: 3,
              }}
            >
              {indexes[index2].symbol}
            </Mui.Typography>
            ({today.toLocaleDateString()})
          </Mui.Container>

          <Mui.Container
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: { xs: 2, md: 0 },
            }}
          >
            {/* Index 1 */}
            <Mui.Container
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              {/* Index 1 */}
              <Mui.FormControl
                fullWidth
                sx={{ m: 1, minWidth: 120 }}
                size="small"
              >
                <Mui.InputLabel id="demo-simple-select-label">
                  Index 1
                </Mui.InputLabel>
                <Mui.Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={index1}
                  label="Index 1"
                  onChange={handleIndex1Change}
                >
                  {indexes.map((index, key) => {
                    return (
                      <Mui.MenuItem value={index.no} key={key}>
                        {index.symbol}
                      </Mui.MenuItem>
                    );
                  })}
                </Mui.Select>
              </Mui.FormControl>

              {/* Type 1 */}
              <Mui.FormControl
                fullWidth
                sx={{ m: 1, minWidth: 120 }}
                size="small"
              >
                <Mui.InputLabel id="demo-simple-select-label">
                  Type 1
                </Mui.InputLabel>
                <Mui.Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={type1}
                  label="Type 1"
                  onChange={handleType1Change}
                >
                  {types.map((type, key) => {
                    return (
                      <Mui.MenuItem value={type.no} key={key}>
                        {type.label}
                      </Mui.MenuItem>
                    );
                  })}
                </Mui.Select>
              </Mui.FormControl>

              {/* Size 1 */}
              <Mui.TextField
                id="outlined"
                label="Size 1 ($/point)"
                size="small"
                type="number"
                sx={{
                  marginLeft: 1,
                  marginRight: -1,
                }}
                value={size1}
                onChange={handleSize1Change}
              />

              {/* Opening Price 1 */}
              <Mui.TextField
                id="outlined"
                label="Opening Price"
                size="small"
                type="number"
                sx={{
                  marginLeft: 1,
                  marginRight: -1,
                }}
                value={openingPrice1}
                onChange={handleOpeningPrice1Change}
              />

              {/* Latest Price 1 */}
              <Mui.Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  marginLeft: 1,
                }}
              >
                <Mui.Typography>Latest Price 1 </Mui.Typography>
                <Mui.Chip label={latestPrice1}></Mui.Chip>
              </Mui.Box>

              {/* Notional Value 1 */}
              <Mui.Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  marginLeft: 1,
                }}
              >
                <Mui.Typography>Notional Value 1 ($) </Mui.Typography>
                <Mui.Chip label={notionalValue1}></Mui.Chip>
              </Mui.Box>

              {/* Profit or Loss 1 */}
              <Mui.Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  marginLeft: 1,
                }}
              >
                <Mui.Typography>Proft/Loss 1 ($)</Mui.Typography>
                <Mui.Chip label={profitOrLoss1}></Mui.Chip>
              </Mui.Box>
            </Mui.Container>

            {/* Index 2 */}
            <Mui.Container
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              {/* Index 2 */}
              <Mui.FormControl
                fullWidth
                sx={{ m: 1, minWidth: 120 }}
                size="small"
              >
                <Mui.InputLabel id="demo-simple-select-label">
                  Index 2
                </Mui.InputLabel>
                <Mui.Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={index2}
                  label="Index 2"
                  onChange={handleIndex2Change}
                >
                  {indexes.map((index, key) => {
                    return (
                      <Mui.MenuItem value={index.no} key={key}>
                        {index.symbol}
                      </Mui.MenuItem>
                    );
                  })}
                </Mui.Select>
              </Mui.FormControl>

              {/* Type 2 */}
              <Mui.FormControl
                fullWidth
                sx={{ m: 1, minWidth: 120 }}
                size="small"
              >
                <Mui.InputLabel id="demo-simple-select-label">
                  Type 2
                </Mui.InputLabel>
                <Mui.Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={type2}
                  label="Type 2"
                  onChange={handleType2Change}
                >
                  {types.map((type, key) => {
                    return (
                      <Mui.MenuItem value={type.no} key={key}>
                        {type.label}
                      </Mui.MenuItem>
                    );
                  })}
                </Mui.Select>
              </Mui.FormControl>

              {/* Size 2 */}
              <Mui.TextField
                id="outlined"
                label="Size 2 ($/point)"
                size="small"
                type="number"
                sx={{
                  marginLeft: 1,
                  marginRight: -1,
                }}
                value={size2}
                onChange={handleSize2Change}
              />

              {/* Opening Price 2 */}
              <Mui.TextField
                id="outlined"
                label="Opening Price"
                size="small"
                type="number"
                sx={{
                  marginLeft: 1,
                  marginRight: -1,
                }}
                value={openingPrice2}
                onChange={handleOpeningPrice2Change}
              />

              {/* Latest Price 2 */}
              <Mui.Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  marginLeft: 1,
                }}
              >
                <Mui.Typography>Latest Price 2 </Mui.Typography>
                <Mui.Chip label={latestPrice2}></Mui.Chip>
              </Mui.Box>

              {/* Notional Value 2 */}
              <Mui.Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  marginLeft: 1,
                }}
              >
                <Mui.Typography>Notional Value 2 ($) </Mui.Typography>
                <Mui.Chip label={notionalValue2}></Mui.Chip>
              </Mui.Box>

              {/* Profit or Loss 2 */}
              <Mui.Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  marginLeft: 1,
                }}
              >
                <Mui.Typography>Proft/Loss 2 ($)</Mui.Typography>
                <Mui.Chip label={profitOrLoss2}></Mui.Chip>
              </Mui.Box>
            </Mui.Container>
          </Mui.Container>

          {/* NET Profit */}
          <Mui.Box
            sx={{
              marginY: 3,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 3,
            }}
          >
            <Mui.Chip
              label={`NET Profit: ${netProfit.toFixed(2)}`}
              color="success"
            ></Mui.Chip>

            <Mui.Chip label={`Ratio: ${ratio.toFixed(2)}`}></Mui.Chip>
            <Mui.Button variant="contained" onClick={handleSave}>
              Save
            </Mui.Button>
          </Mui.Box>
        </Mui.Box>
      </Mui.Modal>
    </Mui.Box>
  );
}
