import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
const axios = require("axios");

const initialState = {
  bots: [],
  botHistory: [],
  loading: false,
};

export const getAllBots = createAsyncThunk("bot/getAllBots", async (req) => {
  const response = await axios.get(`/bot/all`);

  return response.data;
});

export const createBot = createAsyncThunk("bot/createBot", async (req) => {
  const { pairId, percent, takeProfitLimit } = req;

  const response = await axios.post(`/bot`, {
    pairId: pairId,
    percent: percent,
    takeProfitLimit: takeProfitLimit,
  });

  return response.data;
});

export const deleteBot = createAsyncThunk("bot/deleteBot", async (req) => {
  const { botId } = req;

  const response = await axios.delete(`/bot`, {
    data: { botId: botId },
  });

  return response.data;
});

export const { reducer, actions } = createSlice({
  name: "main",
  initialState,
  reducers: {
    setState(state, action) {
      const data = action.payload;

      state.bots = data;
    },
    setBotHistory(state, action) {
      const data = action.payload;

      const finalData = [];

      data.forEach((trade) => {
        const data = {
          id: trade.id,
          tradeId: trade.tradeId,
          year: trade.year,
        };

        const all =
          trade.jan +
          (trade.feb ? ", " : "") +
          trade.feb +
          (trade.mar ? ", " : "") +
          trade.mar +
          (trade.apr ? ", " : "") +
          trade.apr +
          (trade.may ? ", " : "") +
          trade.may +
          (trade.jun ? ", " : "") +
          trade.jun +
          (trade.jul ? ", " : "") +
          trade.jul +
          (trade.aug ? ", " : "") +
          trade.aug +
          (trade.sep ? ", " : "") +
          trade.sep +
          (trade.oct ? ", " : "") +
          trade.oct +
          (trade.nov ? ", " : "") +
          trade.nov +
          (trade.dec ? ", " : "") +
          trade.dec;

        data.series = all;

        finalData.push(data);
      });

      const combinedData = finalData.reduce((acc, curr) => {
        const existingTrade = acc.find((item) => item.tradeId === curr.tradeId);

        if (existingTrade) {
          existingTrade.series = existingTrade.series + ", " + curr.series;
        } else {
          acc.push({
            tradeId: curr.tradeId,
            series: curr.series,
          });
        }

        return acc;
      }, []);

      const final = combinedData.map((item) => {
        const series = item.series.split(",").filter((data) => data);

        const finalSeries = series.map((item) => {
          const splitedItem = item.split("@");

          return [parseInt(splitedItem[0]), parseFloat(splitedItem[1])];
        });

        return { ...item, series: finalSeries };
      });

      state.botHistory = final;

      console.log(state.botHistory);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllBots.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllBots.fulfilled, (state, action) => {
        state.loading = false;
        state.bots = action.payload;
      })
      .addCase(getAllBots.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(createBot.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createBot.fulfilled, (state, action) => {
        state.loading = false;
        state.bots.push({ ...action.payload, positions: [] });
      })
      .addCase(createBot.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteBot.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteBot.fulfilled, (state, action) => {
        state.loading = false;

        // delete bot
        const botIndex = state.bots.findIndex(
          (bot) => bot.id === action.payload.id
        );

        if (botIndex === 0) {
          state.bots = state.bots.slice(1);
        } else {
          state.bots = [
            ...state.bots.slice(0, botIndex),
            ...state.bots.slice(botIndex + 1),
          ];
        }

        toast.success(`Bot ${action.payload.id} has been deleted!`);
      })
      .addCase(deleteBot.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { setState, setBotHistory } = actions;

export default reducer;
