import * as React from "react";
import * as Mui from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "./Navbar";
import { getAllUsers, activeUser } from "../redux/reducers/adminReducer";

export default function Admin(props) {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.admin.users);

  const handleCheck = React.useCallback(
    (id, active) => {
      dispatch(
        activeUser({
          id: id,
          active: active,
        })
      );
    },
    [dispatch]
  );

  React.useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  return (
    <Mui.Box>
      <Navbar />

      <Mui.Container sx={{ maxWidth: "1000", marginTop: 4 }}>
        <Mui.TableContainer component={Mui.Paper}>
          <Mui.Table sx={{ padding: 1 }} aria-label="positions table">
            <Mui.TableHead>
              <Mui.TableRow>
                <Mui.TableCell>ID</Mui.TableCell>
                <Mui.TableCell>Name</Mui.TableCell>
                <Mui.TableCell align="right">Email</Mui.TableCell>
                <Mui.TableCell align="right">Role</Mui.TableCell>
                <Mui.TableCell align="right">Active</Mui.TableCell>
              </Mui.TableRow>
            </Mui.TableHead>

            <Mui.TableBody>
              {users.map((user, index) => (
                <Mui.TableRow>
                  <Mui.TableCell>{user.id}</Mui.TableCell>
                  <Mui.TableCell>{user.name}</Mui.TableCell>
                  <Mui.TableCell align="right">{user.email}</Mui.TableCell>
                  <Mui.TableCell align="right">{user.role}</Mui.TableCell>
                  <Mui.TableCell align="right">
                    <Mui.Switch
                      inputProps={{
                        "aria-labelledby": "switch-list-label-wifi",
                      }}
                      onChange={() => handleCheck(user.id, !user.active)}
                      checked={user.active}
                      color="warning"
                    />
                  </Mui.TableCell>
                </Mui.TableRow>
              ))}
            </Mui.TableBody>
          </Mui.Table>
        </Mui.TableContainer>
      </Mui.Container>
    </Mui.Box>
  );
}
