import * as React from "react";
import * as Mui from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useSelector } from "react-redux";

export default function BotHistoryChart(props) {
  const { tradeId } = props;
  const botHistory = useSelector((state) => state.bot.botHistory);
  const [options, setOptions] = React.useState({
    chart: {
      type: "areaspline",
      zooming: {
        type: "x",
      },
    },
    title: {
      text: "Live Chart of Profit/Loss",
    },
    xAxis: {
      type: "datetime",
    },
    yAxis: {
      title: {
        text: "$",
      },
    },
    plotOptions: {
      areaspline: {
        color: "#32CD32",
        fillColor: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, "#32CD32"],
            [1, "#32CD3200"],
          ],
        },
        threshold: null,
        marker: {
          lineWidth: 1,
          lineColor: null,
          fillColor: "white",
        },
      },
    },
    series: {},
  });

  React.useEffect(() => {
    if (botHistory) {
      const history = botHistory.find((history) => history.tradeId === tradeId);

      if (history) {
        const data = history.series.slice();

        const series = {
          name: "Profit/Loss",
          data: data,
        };
        setOptions((options) => ({
          ...options,
          series: [series],
        }));
      }
    }
  }, [botHistory, tradeId]);

  const Chart = React.useMemo(() => {
    return <HighchartsReact highcharts={Highcharts} options={options} />;
  }, [options]);

  return (
    <Mui.Container maxWidth="false" sx={{ padding: 1 }}>
      {Chart}
    </Mui.Container>
  );
}
