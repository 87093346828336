import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const axios = require("axios");

const initialState = {
  users: [],
  loading: false,
};

export const getAllUsers = createAsyncThunk(
  "admin/getAllUsers",
  async (req) => {
    const response = await axios.post("/admin/users", {
      token: localStorage.getItem("jwtToken"),
    });

    return response.data;
  }
);

export const activeUser = createAsyncThunk("admin/activeUser", async (req) => {
  const { id, active } = req;

  const response = await axios.post("/admin/active", {
    id: id,
    active: active,
  });

  return response.data;
});

export const { reducer, actions } = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllUsers.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(activeUser.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(activeUser.fulfilled, (state, action) => {
        state.loading = false;

        const id = action.payload.id;

        const index = state.users.findIndex((user) => user.id === id);

        if (index === 0) {
          state.users.shift();
          state.users = [action.payload, ...state.users];
        } else {
          state.users = [
            ...state.users.slice(0, index - 1),
            action.payload,
            ...state.users.slice(index + 1),
          ];
        }
      })
      .addCase(activeUser.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default reducer;
