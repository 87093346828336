import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const axios = require("axios");

const initialState = {
  loading: false,
  pairs: [],
};

export const getBestPairs = createAsyncThunk(
  "analytics/getBestPairs",
  async (req) => {
    const { xsd, r } = req;

    const response = await axios.post(`/analyze/analytics/best-pairs`, {
      xsd: xsd,
      r: r,
    });

    return response.data;
  }
);

export const { reducer, actions } = createSlice({
  name: "analytics",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBestPairs.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getBestPairs.fulfilled, (state, action) => {
        state.loading = false;

        state.pairs = action.payload;
      })
      .addCase(getBestPairs.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default reducer;
