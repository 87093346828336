import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const axios = require("axios");

const initialState = {
  rows: [],
  loading: false,
  saveLoading: false,
  latestPrice1: 0,
  latestPrice2: 0,
};

export const getHistory = createAsyncThunk("dashboard/getHistory", async () => {
  const response = await axios.get(`/history/all`);

  return response.data;
});

export const saveHistory = createAsyncThunk(
  "dashboard/createHistory",
  async (req) => {
    const {
      index1,
      type1,
      size1,
      openingPrice1,
      notionalValue1,
      latestPrice1,
      profitOrLoss1,
      index2,
      type2,
      size2,
      openingPrice2,
      notionalValue2,
      latestPrice2,
      profitOrLoss2,
      netProfit,
    } = req;

    const response = await axios.post(`/history`, {
      index1: index1,
      type1: type1,
      size1: size1,
      openingPrice1: openingPrice1,
      notionalValue1: notionalValue1,
      latestPrice1: latestPrice1,
      profitOrLoss1: profitOrLoss1,
      index2: index2,
      type2: type2,
      size2: size2,
      openingPrice2: openingPrice2,
      notionalValue2: notionalValue2,
      latestPrice2: latestPrice2,
      profitOrLoss2: profitOrLoss2,
      netProfit,
    });

    return response.data;
  }
);

export const deleteHistory = createAsyncThunk(
  "dashboard/deleteHistory",
  async (req) => {
    const response = await axios.delete(`/history/${req.id}`);

    return response.data;
  }
);

export const takeProfit = createAsyncThunk(
  "dashboard/takeProfit",
  async (req) => {
    const {
      id,
      notionalValue1,
      latestPrice1,
      profitOrLoss1,
      notionalValue2,
      latestPrice2,
      profitOrLoss2,
      netProfit,
    } = req;

    const response = await axios.put(`/history/take-profit`, {
      id: id,
      notionalValue1: notionalValue1,
      latestPrice1: latestPrice1,
      profitOrLoss1: profitOrLoss1,
      notionalValue2: notionalValue2,
      latestPrice2: latestPrice2,
      profitOrLoss2: profitOrLoss2,
      netProfit: netProfit,
    });

    return response.data;
  }
);

export const getQuote1 = createAsyncThunk(
  "dashboard/getQuote1",
  async (req) => {
    const response = await axios.get(`/history/quote/${req.index}`);

    return response.data.price;
  }
);

export const getQuote2 = createAsyncThunk(
  "dashboard/getQuote2",
  async (req) => {
    const response = await axios.get(`/history/quote/${req.index}`);

    return response.data.price;
  }
);

export const { reducer, actions } = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    resetState(state, action) {
      state.latestPrice1 = 0;
      state.latestPrice2 = 0;
      state.loading = false;
    },
    setState(state, action) {
      const data = action.payload;

      if (state.rows.length) {
        data.forEach((pair) => {
          const oldPair = state.rows.find((row) => row.id === pair.id);
          const oldPairIndex = state.rows.findIndex(
            (row) => row.id === pair.id
          );

          oldPair.notionalValue1 = pair.notionalValue1;
          oldPair.latestPrice1 = pair.latestPrice1;
          oldPair.profitOrLoss1 = pair.profitOrLoss1;
          oldPair.notionalValue2 = pair.notionalValue2;
          oldPair.latestPrice2 = pair.latestPrice2;
          oldPair.profitOrLoss2 = pair.profitOrLoss2;
          oldPair.netProfit = pair.netProfit;

          state.rows = [
            ...state.rows.slice(0, oldPairIndex),
            oldPair,
            ...state.rows.slice(oldPairIndex + 1),
          ];
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHistory.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getHistory.fulfilled, (state, action) => {
        const history = action.payload;

        state.loading = false;
        state.rows = history;
      })
      .addCase(getHistory.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getQuote1.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getQuote1.fulfilled, (state, action) => {
        state.loading = false;
        state.latestPrice1 = action.payload;
      })
      .addCase(getQuote1.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getQuote2.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getQuote2.fulfilled, (state, action) => {
        state.loading = false;
        state.latestPrice2 = action.payload;
      })
      .addCase(getQuote2.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(saveHistory.pending, (state, action) => {
        state.saveLoading = true;
      })
      .addCase(saveHistory.fulfilled, (state, action) => {
        state.saveLoading = false;

        state.rows.push(action.payload);
      })
      .addCase(saveHistory.rejected, (state, action) => {
        state.saveLoading = false;
      })
      .addCase(deleteHistory.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteHistory.fulfilled, (state, action) => {
        state.loading = false;

        const index = state.rows.findIndex(
          (row) => row.id === action.payload.id
        );

        if (index === 0) {
          state.rows = state.rows.slice(1);
        } else {
          state.rows = [
            ...state.rows.slice(0, index),
            ...state.rows.slice(index + 1),
          ];
        }
      })
      .addCase(deleteHistory.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(takeProfit.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(takeProfit.fulfilled, (state, action) => {
        const pair = action.payload;

        const pairIndex = state.rows.findIndex((row) => row.id === pair.id);

        state.loading = false;
        state.rows = [
          ...state.rows.slice(0, pairIndex),
          pair,
          ...state.rows.slice(pairIndex + 1),
        ];
      })
      .addCase(takeProfit.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { resetState, setState } = actions;

export default reducer;
